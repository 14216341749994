<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                       <a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a>ခရီးလမ်းညွှန် အပြည့်အစုံရေးရန် (MM)
                       <select class="form-control" @change="changeRoute()" v-model="routeName" style="width: 200px;display: inline;">
                           <option value="travel-overview">Overview</option>
                           <option value="travel-history">History</option>
                           <option value="travel-checkin">Checkin</option>
                           <option value="travel-photo">Photo</option>
                       </select>
                       <button style="float: right;" class="btn btn-primary" v-b-modal.bv-modal-search>လမ်းညွှန် ရေးမည်</button>
                    </div>
                    <div class="card-body">
                        <div v-for="body in dataList">
                            <div class="row" v-if="body.type == 'image'">
								<div class="col-md-12 text-center">
                                    <a href="javascript:void(0);" class="edit" v-b-modal.bv-modal-search @click="addLayout(body)">
                                        <i class="fas fa-edit"></i></a>
								    <a href="javascript:void(0);" class="delete" v-b-modal.bv-modal-delete @click="deleteData(body)">
                                    <i class="fa fa-trash"></i></a>
									<img :src="env.mediaUrl+body.description" alt="" class="image-fluid"
										style="width:500px%;height:500px;">
								</div>
							</div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row" v-if="body.type == 'text'">
                                        <a href="javascript:void(0);" class="edit" v-b-modal.bv-modal-search @click="addLayout(body)">
                                        <i class="fas fa-edit"></i></a>
                                        <a href="javascript:void(0);" class="delete" v-b-modal.bv-modal-delete @click="deleteData(body)">
                                        <i class="fa fa-trash"></i></a>
                                        <div class="col-md-12 text-center">
                                            <p v-html="body.description"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-delete" ref="my-modal" title="ဖျက်ရန်" hide-footer>
            <p class="my-4">ဖျက်ရန် သေချာပြီလား ?</p>
            <b-button class="mt-3 btn-danger" block @click="deleteItem()">ဖျက်မည်</b-button>
        </b-modal>
        <b-modal id="bv-modal-search" ref="my-modal" hide-footer>
            <template v-slot:modal-name>
                Add Layout
            </template>
            <div class="d-block text-center">
                <select class="form-control" v-model="travelRequest.type" :change="changeLayout()">
                    <option value="">ရွေးချယ်မည်</option>
                    <option value="image">ဓာတ်ပုံ </option>
                </select>
      
                <div class="form-group row mt-2" v-show="imageShow">
                    <div class="col-sm-12">
                        <input type="file" class="form-control" id="inputImage" @change="readImage">
                        <img v-if="travelRequest.image" v-bind:src="travelRequest.image" class="banner-image mt-2"/>
                    </div>
                </div>

                <div class="form-group row mt-2" v-show="imageShow" v-if="currentImage">
                    <div class="col-sm-10">
                        <img v-if="currentImage" :src="env.mediaUrl+currentImage" class="banner-image mt-2"/>
                    </div>
                </div>

                <div class="form-group row mt-2" v-show="textShow">
                    <div class="col-sm-12">
                       <vue-editor id="editor1" v-model="travelRequest.text" :editorToolbar="customToolbar" ></vue-editor>
                    </div>
                </div>
               
            </div>
            <b-button class="mt-3 btn-success" block @click="saveLayout()">လုပ်ဆောင်မည်</b-button>
        </b-modal>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import env from '../../enviorments'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'travelDetail',
        metaInfo: {
            title: "travel Detail",
            titleTemplate: "%s ← SC Dashboard",
        },
        components: {
            Loading
        },
        data() {
            return {
                customToolbar: [
					[{
						header: [false, 1, 2, 3, 4, 5, 6]
					}],
					[{
						align: ""
					}, {
						align: "center"
					}, {
						align: "right"
					}, {
						align: "justify"
					}],
					["bold", "italic", "underline"]
					[{
						list: "ordered"
					}, {
						list: "bullet"
					}],
					["link"], [{ size: ["small", false, "large", "huge"] }]
				],
                env,
                isLoading: false,
                fullPage: true,
                alert: "",
                imageShow: false,
                textShow: false,
                currentImage: "",
                dataList: [],
                routeName: "travel-photo",
                filter: {
                    page: 1,
                    reverse: "asc",
                    sort: "created_at",
                    title: "",
                },
                travelRequest: {
                    id: "",
                    tab: "photo",
                    travel_id: "",
                    type: "",
                    lan: "mm",
                    image: "",
                    text: ""
                }
            }
        },
        watch: {
            '$route' (to, from) {
                this.fetchData()
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                travelDetailListAction: 'travelDetailListAction',
                travelDetailStoreAction: 'travelDetailStoreAction',
                travelDetailUpdateAction: 'travelDetailUpdateAction',
                travelDetailDeleteAction: 'travelDetailDeleteAction'
            }),
            readImage({ target }) {
				const files = target.files
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files[0])
				fileReader.onload = e => {
					this.travelRequest.image = e.target.result
				};
			},
            changeLayout(){
                if(this.travelRequest.type == "image"){
                    this.imageShow = true
                    this.textShow = false
                }
            },
            changeRoute(){
                let replace_route = this.routeName
                this.$router.replace({ path: replace_route, query: { page: this.filter.page, reverse: this.filter.reverse, id: this.travelRequest.travel_id, sort: this.filter.sort, title: this.filter.title} }).catch(()=>{})
                this.isLoading = false;
            },
            async fetchData() {
                this.isLoading = true
                let option = {
                    "travel_id": this.travelRequest.travel_id,
                    "tab": "photo",
                    "lan": "mm"
                }
                await this.travelDetailListAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.dataList = res.data.data
                        this.$router.replace({ path: 'travel-photo', query: { page: this.filter.page, reverse: this.filter.reverse, id: this.travelRequest.travel_id, sort: this.filter.sort, title: this.filter.title} }).catch(()=>{})
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            back(){
                this.$router.replace({ path: 'travel-list', query: { page: this.filter.page, reverse: this.filter.reverse, id: this.travelRequest.travel_id, sort: this.filter.sort, title: this.filter.title} }).catch(()=>{})
            },
            addLayout(body){
                this.travelRequest.id = body.id
                this.travelRequest.type = body.type
                if(body.type == "text"){
                    this.travelRequest.text = body.description
                }
                if(body.type == "image"){
                    this.currentImage = body.description
                }

                this.changeLayout()
            },
            async saveLayout() {
				this.isLoading = true
				let option = this.travelRequest
				await this.travelDetailStoreAction({
					...option
				}).then(res => {
                    this.$refs['my-modal'].hide()
                    this.fetchData()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
            deleteData(data){
                this.travelRequest.id = data.id
            },
            async deleteItem() {
				this.isLoading = true
				let option = {
					id: this.travelRequest.id,
				}
				await this.travelDetailDeleteAction({
					...option
				}).then(res => {
					this.fetchData()
                    this.$refs['my-modal'].hide()
                    this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},

        },
        mounted() {
			this.filter.page = this.$route.query.page
            this.filter.reverse = this.$route.query.reverse
            this.filter.sort = this.$route.query.sort
			this.travelRequest.travel_id = this.$route.query.id
			this.filter.title = this.$route.query.title
            this.fetchData()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
    .banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}

	.edit {
		z-index: 99;
		position: absolute;
		justify-content: end;
		right: 2%;
		margin-right: 3%;
		font-size: 21px;
	}

	.delete {
		z-index: 99;
		position: absolute;
		justify-content: end;
		right: 2%;
		font-size: 21px;
		color: #dc3545;
	}
</style>